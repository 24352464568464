<template>
  <span>
    <v-row dense>
      <v-col cols="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PRP - Percentual de Reclamações Procedentes</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPrpOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PSL - Percentual de Faturas Emitidas Sem Leitura</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPslOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PRF - Percentual de Refaturamentos</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPrfOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PCF - Percentual de Acertos de Faturamento</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPcfOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>

export default {
  name: "DashboardFaturamentoVisaoAneelCardsKpisPorCompetencia",
  props: {
    dados: {
      type: Object,
      required: true,
    },
    metasKpis: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    exportingOptions : {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chartPrpOptions: {},
    chartPslOptions: {},
    chartPrfOptions: {},
    chartPcfOptions: {},
  }),
  mounted() {
    this.setChartsOptions();
  },
  methods: {
    setChartsOptions() {
      this.setChartPrpOptions();
      this.setChartPslOptions();
      this.setChartPrfOptions();
      this.setChartPcfOptions();
    },
    getColumnChartDefaultOptions() {
      return {
        chart: {
          type: 'column',
          zoomType: 'x',
          height: 300,
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: true },
        xAxis: {
          categories: this.categories,
          crosshair: true,
          title: { 
            text: "Competência" 
          }
        },
        yAxis: {
          title: { text: null },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          }
        },
        tooltip: { 
          shared: true,
        },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: [],
        exporting: this.exportingOptions,

      };
    },
    setChartPrpOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = [
        {
          name: "PRP",
          animation: false,
          data: this.categories
            .map(category => {
              return category in this.dados
                ? { x: this.categories.indexOf(category), y: Number(this.dados[category].prp) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            // .filter(val => val !== null && val.y !== null && val.y > 0)
        },
        {
          name: "Meta",
          animation: false,
          stickyTracking: false,
          type: "spline",
          data: this.categories
            .map(category => {
              return category in this.metasKpis
                ? { x: this.categories.indexOf(category), y: Number(this.metasKpis[category].prp) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            .filter(val => val !== null && val.y !== null)
        }
      ];

      this.chartPrpOptions = options;
    },
    setChartPslOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = [
        {
          name: "PSL",
          animation: false,
          data: this.categories
            .map(category => {
              return category in this.dados
                ? { x: this.categories.indexOf(category), y: Number(this.dados[category].psl) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            // .filter(val => val !== null && val.y !== null && val.y > 0)
        },
        {
          name: "Meta",
          animation: false,
          stickyTracking: false,
          type: "spline",
          data: this.categories
            .map(category => {
              return category in this.metasKpis
                ? { x: this.categories.indexOf(category), y: Number(this.metasKpis[category].psl) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            .filter(val => val !== null && val.y !== null)
        }
      ];

      this.chartPslOptions = options;
    },
    setChartPrfOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = [
        {
          name: "PRF",
          animation: false,
          data: this.categories
            .map(category => {
              return category in this.dados
                ? { x: this.categories.indexOf(category), y: Number(this.dados[category].prf) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            // .filter(val => val !== null && val.y !== null && val.y > 0)
        },
        {
          name: "Meta",
          animation: false,
          stickyTracking: false,
          type: "spline",
          data: this.categories
            .map(category => {
              return category in this.metasKpis
                ? { x: this.categories.indexOf(category), y: Number(this.metasKpis[category].prf) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            .filter(val => val !== null && val.y !== null)
        }
      ];

      this.chartPrfOptions = options;
    },
    setChartPcfOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = [
        {
          name: "PCF",
          animation: false,
          data: this.categories
            .map(category => {
              return category in this.dados
                ? { x: this.categories.indexOf(category), y: Number(this.dados[category].pcf) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            // .filter(val => val !== null && val.y !== null && val.y > 0)
        },
        {
          name: "Meta",
          animation: false,
          stickyTracking: false,
          type: "spline",
          data: this.categories
            .map(category => {
              return category in this.metasKpis
                ? { x: this.categories.indexOf(category), y: Number(this.metasKpis[category].pcf) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            .filter(val => val !== null && val.y !== null)
        }
      ];

      this.chartPcfOptions = options;
    },
  },
  watch: {
    dados() {
      this.setChartsOptions();
    }
  },
}

</script>

<style>

</style>